<script lang="ts" setup>
import { useGlobalStore } from '@/stores'
const globalStore = useGlobalStore()
const isDark = computed(() => globalStore.isDark)
interface Menu {
  title: string
  link?: any
  child?: Array<{
    title: string
    link?: any
  }>
}

const menuLists: Ref<Menu[]> = ref([
  {
    title: '關於恆隆行',
    child: [
      {
        title: '品牌故事',
        link: '/brand-story'
      },
      {
        title: '代理品牌',
        link: '/brands'
      },
      {
        title: '最新消息',
        link: '/news'
      },
      // {
      //   title: '永續生活',
      //   link: '/sustainability'
      // },
      {
        title: '人才發展',
        link: '/talent-development'
      }
    ]
  },
  {
    title: '找生活',
    child: [
      {
        title: 'hence, 雜誌',
        link: '/hence'
      },
      {
        title: 'zonezone 概念店',
        link: '/zonezone'
      }
    ]
  },
  {
    title: '找好物',
    child: [
      {
        title: '線上購物',
        link: 'https://shop.hengstyle.com/'
      },
      {
        title: '選品服務',
        link: '/services/select'
      }
    ]
  },
  {
    title: '找服務',
    child: [
      {
        title: '產品維修',
        link: '/services/repair'
      },
      {
        title: '保固註冊',
        link: '/services/warranty'
      },
      {
        title: '到府教學服務',
        link: '/services/inhome'
      },
      {
        title: '深層清潔申請',
        link: '/services/cleaning'
      },
      {
        title: '服務進度查詢',
        link: '/member/service'
      },
    ]
  },
  {
    title: '會員計劃',
    link: '/membership'
  },
  {
    title: '2024光織島',
    link: 'https://www.hengstyle.com/events/sparkleisland'
  }
])
</script>

<template>
  <nav>
    <ul class="menu" :class="{ dark: isDark }">
      <li v-for="(item, index) in menuLists" :key="index">
        <div class="menu__item">
          <template v-if="item.child">
            <div class="menu__title">
              {{ item.title }}
            </div>
            <div class="menu__child">
              <div class="menu__child__inner">
                <nuxt-link v-for="(child, childIndex) in item.child" :key="childIndex" :to="child.link"
                  :title="child.title" :style="{ transitionDelay: `${(childIndex + 2) * 0.1}s` }"
                  class="menu__child__title">
                  {{ child.title }}
                </nuxt-link>
              </div>
            </div>
          </template>
          <template v-else>
            <nuxt-link class="menu__title" :to="item.link" :title="item.title">
              {{ item.title }}
            </nuxt-link>
          </template>
        </div>
      </li>
    </ul>
  </nav>
</template>

<style lang="scss" scoped>
.menu {
  display: flex;
  align-items: center;

  &.dark {
    .menu {
      &__title {
        color: $color-white-1;
      }
    }
  }

  li {
    padding-left: toRem(13);
    padding-right: toRem(13);
  }

  &__item {
    position: relative;

    &:hover {
      .menu {
        &__title {
          &::after {
            width: 100%;
          }
        }

        &__child {
          // opacity: 1;
          pointer-events: auto;

          // transform: translateX(-50%) scale(1);
          &__inner {
            transform: scaleX(1);

            a {
              transform: translateY(0);
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &__title {
    position: relative;
    cursor: pointer;
    font-size: toRem(16);
    padding-top: toRem(8);
    padding-bottom: toRem(8);

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      width: 0;
      height: 2px;
      background-color: $color-gray-3;
      transition: all 0.5s ease-in-out;
    }
  }

  &__child {
    position: absolute;
    left: 50%;
    // transform: translateX(-50%) scale(1.2);
    transform: translateX(-50%);
    top: 100%;
    width: toRem(185);
    padding-top: toRem(13);
    // opacity: 0;
    pointer-events: none;
    // transition: all 0.6s ease-in-out;

    &__inner {
      background-color: $color-gray-3;
      padding-top: toRem(10);
      padding-bottom: toRem(10);
      transform: scaleX(0);
      transition: transform 0.6s cubic-bezier(0.87, 0, 0.13, 1);

      a {
        transform: translateY(toRem(10));
        opacity: 0;
        transition: all 0.6s cubic-bezier(0.87, 0, 0.13, 1);
      }
    }

    &__title {
      display: block;
      padding-top: toRem(5);
      padding-bottom: toRem(5);
      font-size: toRem(16);
      text-align: center;
    }
  }
}
</style>
